<template>
  <div id="personal-info">
    <b-form class="mx-auto" id="personal-info-form" v-if="user">
      <b-row class="form-rows">
        <b-col md="12" lg="6">
          <b-form-group label="Name" label-cols="2" label-cols-lg="12">
            <b-form-input v-model="updateUser.forename" placeholder="Enter your first name"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="12" lg="6">
          <b-form-group label="Surname" label-cols="3" label-cols-lg="12">
            <b-form-input v-model="updateUser.surname" placeholder="Enter surname"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="12" lg="6">
          <b-form-group label="Practice Name" label-cols="4" label-cols-lg="12">
            <b-form-input v-model="updateUser.practiceName" placeholder="Practice Name"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="12" lg="6">
          <b-form-group label="Phone" label-cols="2" label-cols-lg="12">
            <b-form-input
              type="tel"
              :state="phoneFieldState"
              v-model="updateUser.phoneNumbers[0].number"
              placeholder="Phone number"
            ></b-form-input>
            <b-form-invalid-feedback :state="phoneFieldState">Invalid phone number entered.</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col md="12" lg="6">
          <b-form-group label="Address 1" label-cols="3" label-cols-lg="12">
            <b-form-input v-model="updateUser.addresses[0].address1" placeholder="Address line 1"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="12" lg="6">
          <b-form-group label="Address 2" label-cols="3" label-cols-lg="12">
            <b-form-input v-model="updateUser.addresses[0].address2" placeholder="Address line 2"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="12" lg="6">
          <b-form-group label="Address 3" label-cols="3" label-cols-lg="12">
            <b-form-input v-model="updateUser.addresses[0].address3" placeholder="Address line 3"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="5" lg="6" class="minimal-col">
          <b-form-group label="City" label-cols="3" label-cols-md="3" label-cols-lg="12">
            <b-form-input v-model="updateUser.addresses[0].city" placeholder="City"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="7" lg="4">
          <b-form-group label="County" label-cols="3" label-cols-md="4" label-cols-lg="12">
            <b-form-input v-model="updateUser.addresses[0].county" placeholder="County"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="5" lg="4" class="minimal-col">
          <b-form-group label="Postcode" label-cols="3" label-cols-md="6" label-cols-lg="12">
            <b-form-input v-model="updateUser.addresses[0].postcode" placeholder="Postcode"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="7" lg="4">
          <b-form-group label="Country" label-cols="3" label-cols-md="4" label-cols-lg="12">
            <b-form-select v-model="updateUser.addresses[0].country" :options="countries" />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="action-buttons">
        <b-button pill class="mr-2" variant="outline-secondary" @click.stop="reset">Clear Changes</b-button>
        <b-button variant="primary" v-if="!busySaving" pill @click.stop="saveUser">Save</b-button>
        <b-button variant="primary" v-else disabled pill @click.stop="saveUser">
          <Busy :size="1.3" :margin="0" />
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#personal-info {
  width: 66%;
  vertical-align: top;
  display: inline-block;
  padding: 0 1rem 0 1rem;

  @media screen and (max-width: 1292px) {
    width: 60%;
  }

  @media screen and (max-width: 979px) {
    width: 57%;
  }

  @media screen and (max-width: 869px) {
    width: 50%;
    .minimal-col {
      padding-right: 0 !important;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;

    #personal-info-form {
      max-width: 500px;
      margin-top: 20px;

      #cboCountry {
        max-height: 50px !important;
      }

      .form-rows > div {
        padding: 0 !important;

        .col-form-label {
          font-size: 13px;
        }
      }
    }

    .minimal-col {
      .form-row {
        padding-right: 15px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .minimal-col {
      .form-row {
        padding-right: 0;
      }
    }
  }

  .action-buttons {
    float: right;
    margin-bottom: 1.5rem;
    margin-top: 10px;
    .btn-primary {
      width: 6rem; // set width for displaying spinner
    }
  }
}
</style>

<script>
import AddressHelper from "@/helper/addresshelper";
import ApiHelper from "@/helper/apihelper";
import { EventBus } from "@/components/eventbus";
import Busy from "@/components/Busy";
import PhoneHelper from "@/helper/phonehelper";

export default {
  name: "UserInformationForm",
  props: {
    user: Object
  },
  components: {
    Busy
  },
  data() {
    return {
      countries: AddressHelper.COUNTRIES,
      backupUser: {},
      updateUser: {
        addresses: [{}],
        phoneNumbers: [{}]
      },

      phoneFieldState: null,
      busySaving: false,
      busyLoading: true
    };
  },
  updated() {
    if (this.user && Object.keys(this.backupUser).length == 0) {
      this.updateUser = JSON.parse(JSON.stringify(this.user));

      if (!this.updateUser.phoneNumbers || !this.updateUser.phoneNumbers[0]) {
        this.updateUser.phoneNumbers = [{}];
      }

      if (!this.updateUser.addresses || !this.updateUser.addresses[0]) {
        this.updateUser.addresses = [{ country: "" }];
      } else if (!this.updateUser.addresses[0].country) {
        this.updateUser.addresses[0].country = "";
      }

      this.backupUser = JSON.parse(JSON.stringify(this.updateUser));
    }
  },
  methods: {
    async saveUser() {
      this.busySaving = true;
      this.phoneFieldState = null;

      if (
        this.updateUser.phoneNumbers &&
        this.updateUser.phoneNumbers[0] &&
        this.updateUser.phoneNumbers[0].number &&
        !PhoneHelper.isValid(this.updateUser.phoneNumbers[0].number)
      ) {
        this.busySaving = false;
        this.phoneFieldState = false;
        return false;
      }

      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}users/${this.user.userId}`, {
          user: this.updateUser
        });

        if (response.data.status === "success") {
          this.$emit("user", this.updateUser);
          this.showMessage("success", "User account details saved successfully");
        } else {
          this.showMessage("warning", "There was a problem saving user account details.");
        }
      } catch {
        this.showMessage("warning", "There was a problem saving user account details.");
      } finally {
        this.busySaving = false;
      }
    },

    reset() {
      this.updateUser = JSON.parse(JSON.stringify(this.backupUser));
      this.phoneFieldState = null;
    },

    showMessage(variant, message) {
      EventBus.$emit("show-toast", {
        message: message,
        variant: variant
      });
    }
  }
};
</script>