<template>
  <div v-if="user" id="user-account-actions">
    <b-modal
      v-model="visibleModal"
      @hide="visibleModal = false"
      @ok="confirmedRemoveAccount"
      ok-title="Delete Account"
      lazy
      title="Delete Account"
    >
      <p>Are you sure you want to remove account for {{ this.user.email }}?</p>
      <p v-if="usertype == 'my-user'">
        You will no longer be able to login or access the data and your reports.
      </p>
      <p v-else>The user will no longer be able to login or access the data and their reports.</p>
      <p v-if="usertype == 'my-user'">
        <strong>Note:</strong> If other users are still linked to your organisation the data will
        not be deleted.
      </p>
      <p v-else>
        <strong>Note:</strong> If other users are still linked to this users organisation the data
        will not be deleted.
      </p>
    </b-modal>

    <b-card id="share-organisations-card" class="reducer-card reducer-box-shadow">
      <h5 v-if="usertype == 'my-user'">Share My Organisations</h5>
      <h5 v-else>Share Users Organisations</h5>
      <p v-if="usertype == 'my-user'">
        You can share all of your organisations with another user. This will give the nominated user
        the same access to the organisations as you. Enter the ID of the user you want to share your
        organisations with:
      </p>
      <p v-else>
        You can share all of the users organisations with another user. This will give the nominated
        user the same access to the organisations as this user. Enter the ID of the user you want to
        share the organisations with:
      </p>

      <b-input-group class="mt-3">
        <b-input
          type="text"
          placeholder="Enter User ID"
          v-model="newUserId"
          :state="validUserId"
          @input="validUserId = null"
        />
        <b-input-group-append>
          <b-button v-if="loading" variant="primary" disabled>
            <Busy :size="1.3" :margin="0" class="px-3" />
          </b-button>
          <b-button v-else type="submit" variant="primary" @click.stop="submitData"
            >Submit</b-button
          >
        </b-input-group-append>
      </b-input-group>

      <b-form-invalid-feedback :state="validUserId"
        >Invalid user ID entered.</b-form-invalid-feedback
      >
    </b-card>

    <b-card class="reducer-box-shadow reducer-card" v-if="usertype == 'my-user'">
      <h5 class="mb-2">Change Password</h5>

      <span id="passwordErrorMessage" v-if="passwordErrorMessage">
        <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
        {{ passwordErrorMessage }}
      </span>

      <b-input
        :state="oldState"
        class="my-2"
        type="password"
        placeholder="Old Password"
        v-model="oldPassword"
      ></b-input>
      <b-input
        :state="newState"
        class="mb-2"
        type="password"
        v-model="newPassword"
        placeholder="New Password"
      ></b-input>
      <b-input
        :state="newVerState"
        type="password"
        v-model="newPasswordVerification"
        placeholder="Re-enter New Password"
      ></b-input>

      <b-button
        v-if="!busyUpdating"
        class="float-right mt-3"
        variant="primary"
        pill
        @click="changePassword"
        >Submit</b-button
      >
      <b-button v-else class="float-right" disabled variant="danger" pill>
        <Busy :size="1.3" :margin="0" class="px-3" />
      </b-button>
    </b-card>

    <b-card class="reducer-box-shadow reducer-card mb-5">
      <h5 v-if="usertype == 'my-user'">Delete Account</h5>
      <h5 v-else>Delete the users account</h5>

      <p v-if="usertype == 'my-user'">
        Deleting your account will remove all accounting data and delete each organisation you are
        part of where you are the only user. If an organisation has other users, you will be removed
        from the organisation but the organisation will remain active for other users. Deleting your
        account will remove all of the user data we hold for you and you will be logged out. This
        action will not affect any data in your accounting platforms.
      </p>
      <p v-else>
        Deleting this users account will remove all accounting data and delete each organisation
        they are part of where they are the only user. If an organisation has other users, this user
        will be removed from the organisation but the organisation will remain active for other
        users. Deleting this users account will remove all of the user data we hold for this user
        and this user will be logged out. This action will not affect any data in the users
        accounting platforms.
      </p>

      <b-button
        class="float-right"
        v-if="!busyDeleting"
        @click.stop="visibleModal = true"
        variant="danger"
        pill
      >
        <font-awesome-icon class="mr-1" :icon="['fas', 'exclamation-circle']" />Delete Account
      </b-button>
      <b-button v-else class="float-right" disabled variant="danger" pill>
        <Busy :size="1.3" :margin="0" class="px-3" />
      </b-button>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/common.scss";
#user-account-actions {
  .reducer-card {
    border-radius: 15px;
    margin: 1rem 0 1rem 0;
  }

  #share-organisations-card .btn {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  #passwordErrorMessage {
    color: $color-red;
    font-size: 13px;
  }

  h5 {
    font-weight: bold;
    color: $color-font-headings;
  }
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import IDHelper from "@/helper/idhelper";
import Busy from "@/components/Busy";
import CognitoAuth from "@/cognito/cognitoauth";

export default {
  name: "UserAccountActions",
  components: { Busy },
  props: {
    user: Object,
    admin: Boolean,
    usertype: {
      type: String,
      default: "my-user"
    }
  },
  data() {
    return {
      loading: false,
      busyDeleting: false,
      busyUpdating: false,

      oldState: null,
      newState: null,
      newVerState: null,
      passwordErrorMessage: null,

      oldPassword: null,
      newPassword: null,
      newPasswordVerification: null,

      newUserId: null,
      validUserId: null,
      visibleModal: false
    };
  },
  methods: {
    async submitData() {
      if (!IDHelper.isValidUuid(this.newUserId)) {
        this.validUserId = false;
      } else {
        this.loading = true;
        const client = await ApiHelper.http();
        await client
          .post(
            `${ApiHelper.endPoint()}users/${this.user.userId}/organisations`,
            `newUserId=${this.newUserId}`
          )
          .then(() => {
            this.showMessage(
              `Successfully shared your organisations with user ${this.newUserId}.`,
              "success"
            );
          })
          .catch(() => {
            this.showMessage(
              `There was a problem sharing your organisations with user ${this.newUserId}.`,
              "warning"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    async confirmedRemoveAccount() {
      this.busyDeleting = true;

      try {
        let client = await ApiHelper.http();
        var response = await client.delete(`${ApiHelper.endPoint()}users/${this.user.userId}`);

        if (response.data.status === "success") {
          this.$router.push({
            name: this.admin ? "all-users" : "signout"
          });
        } else {
          this.showMessage(
            "Sorry, there was a problem with that request. The user account was not deleted.",
            "warning"
          );
        }
      } catch {
        this.showMessage(
          "Sorry, there was a problem with that request. The user account was not deleted.",
          "warning"
        );
      } finally {
        this.busyDeleting = false;
      }
    },

    cognitoFail(err) {
      this.busyUpdating = false;

      switch (err.code) {
        case "NotAuthorizedException":
          this.showMessage(
            "Password cannot be changed because you are logged in with an external provider.",
            "warning"
          );
          break;
        default:
          this.showMessage(err.message, "warning");
      }
    },
    cognitoSuccess() {
      this.busyUpdating = false;
      this.showMessage("Password change has been successful.", "success");
    },

    async changePassword() {
      this.passwordErrorMessage = null;
      this.oldState = null;
      this.newState = null;
      this.newVerState = null;

      if (!this.newPassword || !this.oldPassword || !this.newPasswordVerification) {
        this.passwordErrorMessage = "All input fields are required.";
        this.oldState = !this.oldPassword ? false : null;
        this.newState = !this.newPassword ? false : null;
        this.newVerState = !this.newPasswordVerification ? false : null;
      } else if (this.newPassword == this.oldPassword) {
        this.passwordErrorMessage = "New password must be different from the old password";
        this.newState = false;
      } else if (this.newPasswordVerification != this.newPassword) {
        this.newVerState = false;
        this.passwordErrorMessage = "Passwords do not match";
      } else {
        this.busyUpdating = true;

        await CognitoAuth.changePassword(
          this.oldPassword,
          this.newPassword,
          this.cognitoSuccess,
          this.cognitoFail
        );
      }
    }
  }
};
</script>