<template>
  <div id="general-info">
    <b-card class="info-cards reducer-box-shadow mx-auto">
      <span>User ID</span>
      <p>{{ user.userId }}</p>
      <span>Email</span>
      <p class="m-0">
        {{ user.email }}<b-badge class="sales-role-badge" v-if="user.cpmId">CPM</b-badge
        ><b-badge class="sales-role-badge" v-if="user.apmId">APM</b-badge>
      </p>
    </b-card>

    <b-card class="info-cards reducer-box-shadow mx-auto">
      <h5>Preferences</h5>

      <b-row id="preferences-content">
        <b-col cols="10">
          <p>Show Xero consent screen every time I start a new analysis:</p>
        </b-col>

        <b-col cols="2">
          <b-check size="lg" class="float-right" v-model="updateUser.settings.xeroConsent" switch />
        </b-col>

        <b-col>
          <p class="mb-2">Customise user experience:</p>
          <b-radio-group class="ml-3">
            <b-radio class="mb-1" size="lg" :value="true" v-model="updateUser.settings.accountant">
              <span v-if="usertype == 'my-user'">I'm an accountant, bookkeeper or advisor</span>
              <span v-else>The user is an accountant, bookkeeper or advisor</span>
            </b-radio>
            <b-radio size="lg" :value="false" v-model="updateUser.settings.accountant">
              <span v-if="usertype == 'my-user'">I own, manage, or help run a business</span>
              <span v-else>The user is an owner, manager, or helps running a business</span>
            </b-radio>
          </b-radio-group>
        </b-col>
      </b-row>

      <b-button v-if="busy" class="preferences-button" pill disabled>
        <Busy :size="1.3" :margin="0" />
      </b-button>

      <b-button v-else class="preferences-button" pill @click="savePreferences">Save</b-button>
    </b-card>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#general-info {
  width: 33%;
  vertical-align: top;
  display: inline-block;
  .sales-role-badge {
    background-color: $color-pink-main;
    margin-left: 8px;
  }
  @media screen and (max-width: 1292px) {
    width: 40%;
  }

  @media screen and (max-width: 979px) {
    width: 43%;
  }

  @media screen and (max-width: 869px) {
    width: 50%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;

    span {
      font-size: 12px;
    }

    p,
    .custom-control-label {
      font-size: 0.9rem;
    }

    h5 {
      font-size: 1.1rem;
    }
  }

  .info-cards {
    border-radius: 15px;
    max-width: 525px;
    margin: 1rem 0 1rem 0;

    span {
      font-size: 14px;
    }

    p,
    h5 {
      font-weight: bold;
    }

    h5 {
      color: $color-font-headings;
    }

    #preferences-content {
      padding-top: 0.5rem;
      margin-bottom: 1.5rem;
    }

    .preferences-button {
      width: 6rem; // set width for displaying spinner
      float: right;

      background-color: $color-pink-main;
      border-color: $color-pink-main;
    }
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import { EventBus } from "@/components/eventbus";
import Busy from "@/components/Busy";
import UserSettingsHelper from "@/helper/usersettingshelper";

export default {
  name: "UserInformationCards",
  props: {
    user: Object,
    usertype: {
      type: String,
      default: "my-user"
    }
  },
  components: {
    Busy
  },
  data() {
    return {
      busy: false,
      updateUser: {
        settings: { xeroConsent: null, accountant: null }
      }
    };
  },
  updated() {
    Object.assign(this.updateUser, this.user);
  },
  methods: {
    async savePreferences() {
      this.busy = true;

      let client = await ApiHelper.http();
      client
        .post(`${ApiHelper.endPoint()}users/${this.user.userId}`, {
          user: { settings: this.updateUser.settings }
        })
        .then(() => {
          this.showMessage("success", "User preferences saved successfully.");
          UserSettingsHelper.saveToLocalStorage(this.settings);
        })
        .catch(() => {
          this.showMessage("warning", "There was a problem saving user preferences.");
        })
        .finally(() => {
          this.busy = false;
        });
    },

    emitUpdate() {
      this.$emit("updateUser", this.updateUser);
    },

    showMessage(variant, message) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>