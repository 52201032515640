<template>
  <div id="organisation-links" class="mb-5" ref="orgLinks">
    <b-modal
      v-model="dModal.active"
      @hide="dModalHide"
      :title="dModal.title"
      @ok="confirmModalAction(dModal)"
      ok-title="Proceed"
      cancel-title="Cancel"
      lazy
    >
      <p>{{ dModal.content }}</p>
    </b-modal>

    <b-modal
      v-if="admin"
      v-model="analyseModal.visible"
      hide-header
      ok-title="Analyse"
      @ok="analyse"
      @hide="analyseModalHide"
      cancel-title="Cancel"
      lazy
    >
      <h5>Select months of data to analyse:</h5>
      <b-input
        id="monthsOfData"
        v-model="analyseModal.months"
        type="number"
        min="1"
        max="60"
        required
      />
    </b-modal>

    <div v-if="busyLoading" disabled class="mt-5">
      <Busy primary :size="3" text="Loading organisations" />
    </div>
    <div v-else class="mt-2">
      <div class="px-1 list-actions">
        <b-select
          id="sort-dropdown"
          class="d-block d-md-inline-block my-2"
          v-model="sortby"
          v-if="links && links.length > 0"
          :options="sortingOptions"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>Sort by</b-form-select-option>
          </template>
        </b-select>

        <b-form-checkbox
          class="ml-2 d-block d-md-inline-block"
          v-model="filterOnlyIntegrated"
          v-if="connectedOrganisations.length > 0"
          @change="currentPage = 1"
          >Only show connected organisations</b-form-checkbox
        >

        <b-button
          v-if="links && links.length > 0"
          variant="light"
          size="sm"
          @click="fetchConnectionData(true)"
          :disabled="busyConnections"
          :class="['border sync-btn', {'top-margin': totalrows <= perPage }]"
        >
          <Busy v-if="busyConnections" primary :size="1" class="m-0" />
          <div v-else>
            <font-awesome-icon class="text-muted mr-1" :icon="['fas', 'sync']" />
            Sync <span class="d-none d-md-inline">connections</span>
          </div>
        </b-button>

        <div class="d-block d-md-inline-block float-right my-2">
          <b-pagination
            align="right"
            v-if="totalrows > perPage"
            :total-rows="totalrows"
            :per-page="perPage"
            v-model="currentPage"
          ></b-pagination>
        </div>
      </div>

      <div v-if="!sortedOrgs || sortedOrgs.length == 0" class="d-flex justify-content-center">
        <p>You have no organisations to display</p>
      </div>

      <b-row
        v-else
        class="reducer-box-shadow organisation-row-card"
        v-for="org in sortedOrgs"
        :key="org.organisationId"
        :id="org.organisationId"
      >
        <b-col cols="12" md="8" class="p-0 my-2 d-flex align-items-center">
          <b-img
            :title="organisations[org.organisationId].organisationSource"
            v-if="organisations[org.organisationId].organisationSource == 'Xero'"
            class="icon mx-3"
            rounded="circle"
            :src="require('@/assets/images/xero.png')"
          />
          <b-img
            :title="organisations[org.organisationId].organisationSource"
            v-if="organisations[org.organisationId].organisationSource == 'QuickBooks'"
            class="icon mx-3"
            rounded="circle"
            :src="require('@/assets/images/qb.png')"
          />
          <b-img
            :title="organisations[org.organisationId].organisationSource"
            v-if="organisations[org.organisationId].organisationSource == 'ArmstrongWatson'"
            class="icon mx-3"
            rounded="circle"
            :src="require('@/assets/images/armstrongwatson.png')"
          />

          <p class="m-0">
            <a v-if="admin" :href="openOrgDetails(org.organisationId)" target="_blank">{{
              organisations[org.organisationId].name
            }}</a>
            <span v-else>{{ organisations[org.organisationId].name }}</span>
          </p>

          <div class="ml-3 d-flex justify-content-end">
            <b-badge
              v-for="role in org.roles"
              variant="light"
              :key="role.id"
              v-b-tooltip.hover
              :title="role.id | roleDescription"
              >{{ role.id }}</b-badge
            >
          </div>
        </b-col>

        <b-col
          v-if="busyConnections || org.busy"
          cols="12"
          md="4"
          class="d-flex justify-content-end align-self-center align-items-center user-actions"
        >
          <b-skeleton
            v-if="connectedOrganisationsMap[org.organisationId]"
            type="button"
            class="mr-2 rounded-pill"
            width="100px"
          />
          <b-skeleton
            v-if="connectedOrganisationsMap[org.organisationId]"
            type="button"
            class="mr-2 rounded-pill"
            width="85px"
          />
          <b-skeleton
            v-if="!connectedOrganisationsMap[org.organisationId]"
            class="mr-2"
            width="100px"
          />
          <b-skeleton type="button" class="mr-2 rounded-pill" width="60px" />
        </b-col>
        <b-col
          v-else
          cols="12"
          md="4"
          class="d-flex justify-content-end align-self-center user-actions"
        >
          <b-button
            v-if="connectedOrganisationsMap[org.organisationId] && admin"
            class="mr-2"
            size="sm"
            variant="primary"
            pill
            @click.stop="
              analyseModal.visible = true;
              analyseModal.organisation = org;
            "
            :disabled="org.busy"
          >
            New Analysis
          </b-button>
          <b-button
            variant="danger"
            class="mr-2"
            size="sm"
            @click.stop="deleteDisconnectLink(org, 'disconnect')"
            :disabled="org.busy"
            v-if="connectedOrganisationsMap[org.organisationId]"
            pill
          >
            Disconnect
          </b-button>
          <div v-else class="mr-2">
            <b-badge variant="light">NOT CONNECTED</b-badge>
          </div>
          <b-button
            v-if="admin"
            variant="danger"
            class="mr-2"
            size="sm"
            pill
            @click.stop="deleteDisconnectLink(org, 'delete')"
            :disabled="org.busy"
            >Delete
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div class="d-inline-block float-right">
      <b-pagination
        class="mb-5"
        align="right"
        v-if="totalrows > perPage"
        :total-rows="totalrows"
        :per-page="perPage"
        v-model="currentPage"
      ></b-pagination>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#organisation-links {
  position: relative;
  .icon {
    max-width: 2rem;
  }
  #sort-dropdown {
    max-width: 200px;
  }
  .organisation-row-card {
    width: 100% !important;
    margin: 1rem 0.5rem 1rem 0.5rem;
    border-radius: 10px;

    .user-actions {
      & > button {
        flex-shrink: 0;
      }
    }
  }

  a {
    color: $color-font-headings;
  }

  .sync-btn {
    position: absolute;
    float: right;
    right: 0;
    top: -2.5rem;
    min-width: 5rem;
    &.top-margin {
      margin-top: 3.75rem;
    }
  }

  @media screen and (max-width: 767px) {
    .user-actions {
      border-top: 1px solid $color-grey-lighter1;
      padding: 10px 0 10px 0 !important;
    }
    .sync-btn {
      top: 0;
      margin-top: 0;
    }
  }
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";

export default {
  name: "UserOrgsAndIntegrations",
  props: {
    admin: Boolean,
    userId: String
  },
  components: {
    Busy
  },
  data() {
    return {
      sortingOptions: [
        { value: "name", text: "Organisation Name" },
        { value: "source", text: "Organisation Source" },
        { value: "lastExtract", text: "Last Extract Date" }
      ],
      sortby: null,
      links: [],
      organisations: {},
      connectedOrganisations: [],
      connectedOrganisationsMap: {},
      busyLoading: false,
      busyConnections: false,
      filterOnlyIntegrated: false,
      currentPage: 1,
      perPage: 30,
      totalrows: null,
      dModal: {
        title: "",
        content: "",
        org: null,
        type: "",
        active: false
      },
      analyseModal: {
        message: null,
        visible: false,
        months: 14,
        organisation: null
      }
    };
  },
  computed: {
    sortedOrgs: function() {
      let processedLink = this.links;

      if (this.filterOnlyIntegrated) {
        processedLink = processedLink.filter(item => {
          return (
            this.connectedOrganisationsMap && this.connectedOrganisationsMap[item.organisationId]
          );
        });
      }

      if (this.sortby == "name") {
        processedLink.sort((a, b) =>
          this.organisations[a.organisationId].name.toLowerCase() <
          this.organisations[b.organisationId].name.toLowerCase()
            ? -1
            : 1
        );
      } else if (this.sortby == "source") {
        processedLink.sort((a, b) =>
          this.organisations[a.organisationId].organisationSource <
          this.organisations[b.organisationId].organisationSource
            ? -1
            : 1
        );
      } else if (this.sortby == "lastExtract") {
        processedLink.sort((a, b) =>
          this.organisations[a.organisationId].lastExtractJob >
          this.organisations[b.organisationId].lastExtractJob
            ? -1
            : 1
        );
      }

      return this.paginate(processedLink);
    }
  },
  methods: {
    paginate(arr) {
      this.totalrows = arr.length;
      return arr.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },

    openOrgDetails(orgid) {
      const a = this.$router.resolve({
        name: "organisation-details",
        params: {
          organisationId: orgid
        }
      });
      return a.href;
    },

    async lazyLoadOrgs() {
      if (!this.organisations || Object.keys(this.organisations).length === 0) {
        try {
          let response = await Promise.all([this.fetchConnectionData(), this.fetchOrganisations()]);
          const organisationResponse = response[1];

          if (organisationResponse && organisationResponse.organisations) {
            organisationResponse.organisations.forEach(element => {
              this.organisations[element.organisationId] = element;
            });

            this.links = organisationResponse.links;
          }
        } finally {
          this.busyLoading = false;
        }
      }
    },

    async fetchOrganisations() {
      this.busyLoading = true;
      let organisationResponse = {};

      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}organisations?userId=${
            this.userId
          }&includeLinks=true&itemsPerPage=999`
        );
        if (response.data.status === "success") {
          organisationResponse = response.data;
        } else {
          this.showMessage("warning", "There was a problem getting user details.");
        }
      } catch {
        this.showMessage("warning", "There was a problem getting user details.");
      }

      return organisationResponse;
    },

    async fetchConnectionData(validate = false) {
      let connectionsResponse = [];
      this.busyConnections = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}connections?userId=${this.userId}&validateTokens=${validate}`
        );
        if (response.data.status === "success") {
          this.connectedOrganisations = response.data.organisations;

          this.connectedOrganisations.forEach(element => {
            this.connectedOrganisationsMap[element.organisationId] = element;
          });

          connectionsResponse = response.data;
        }
      } catch {
        this.showMessage("warning", "There was a problem getting connected organisations.");
      } finally {
        this.busyConnections = false;
      }
      return connectionsResponse;
    },

    //DELETE/ DISCONNECT ORGANISATION
    updateOrganisationCard(org, type) {
      if (type == "disconnect") {
        this.$delete(this.connectedOrganisationsMap, org.organisationId);
      } else if (type == "delete") {
        this.links.splice(
          this.links.findIndex(x => x.organisationId == org.organisationId),
          1
        );
        delete this.organisations[org.organisationId];
      } else {
        this.showMessage("warning", "The requested action was not recognised");
      }
    },
    deleteDisconnectLink(organisation, type) {
      if (type == "delete") {
        this.dModal.title = "Delete organisation";
        this.dModal.content =
          "Are you sure you want to delete " +
          this.organisations[organisation.organisationId].name +
          " from Reducer?";
        this.dModal.org = organisation;
        this.dModal.active = true;
        this.dModal.type = type;
      } else if (type == "disconnect") {
        this.dModal.title = "Disconnect organisation";
        this.dModal.content =
          "Are you sure you want to disconnect " +
          this.organisations[organisation.organisationId].name +
          " from " +
          this.organisations[organisation.organisationId].organisationSource +
          "?";
        this.dModal.org = organisation;
        this.dModal.active = true;
        this.dModal.type = type;
      } else {
        this.showMessage("warning", "The requested action was not recognised");
      }
    },
    dModalHide() {
      this.dModal.active = false;
      this.dModal.title = "";
      this.dModal.content = "";
      this.dModal.org = null;
      this.dModal.type = "";
    },
    confirmModalAction(dModal) {
      if (dModal.type == "delete") {
        this.confirmedRemoveLink(dModal.org);
      } else if (dModal.type == "disconnect") {
        this.confirmedDisconnect(dModal.org);
      } else {
        this.showMessage("warning", "The requested action was not recognised");
      }
    },
    async confirmedRemoveLink(org) {
      this.$set(org, "busy", true);
      try {
        let client = await ApiHelper.http();
        var response = await client.delete(
          `${ApiHelper.endPoint()}users/${org.userId}/organisations/${org.organisationId}`
        );

        if (response.data.status === "success") {
          this.updateOrganisationCard(org, "delete");
          this.showMessage("success", "The request was successful.");
        } else {
          this.showMessage(
            "warning",
            "Sorry, there was a problem with that request. The organisation was not deleted."
          );
        }
      } catch {
        this.showMessage(
          "warning",
          "Sorry, there was a problem with that request. The organisation was not deleted."
        );
      } finally {
        this.$set(org, "busy", false);
      }
    },
    async confirmedDisconnect(org) {
      this.$set(org, "busy", true);
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}connections/disconnect?userId=${org.userId}&organisationId=${
            org.organisationId
          }`
        );

        if (response.data.status === "success") {
          this.updateOrganisationCard(org, "disconnect");
          this.showMessage("success", "The organisation has been disconnected.");
        } else {
          this.showMessage(
            "warning",
            "Sorry, there was a problem with that request. The organisation was not disconnected."
          );
        }
      } catch {
        this.showMessage(
          "warning",
          "Sorry, there was a problem with that request. The organisation was not disconnected."
        );
      } finally {
        this.$set(org, "busy", false);
      }
    },

    //ANALYSE ORGANISATION
    analyseModalHide() {
      this.analyseModalmessage = null;
      this.analyseModalvisible = false;
      this.analyseModalmonths = 14;
      this.analyseModalorganisation = null;
    },
    async analyse() {
      let org = this.analyseModal.organisation;
      let monthsOfData = this.analyseModal.months;
      let platform =
        this.organisations[org.organisationId].organisationSource == "QuickBooks"
          ? "quickBooks"
          : "xero";

      this.$set(org, "busy", true);
      const client = await ApiHelper.http();
      await client
        .post(
          `${ApiHelper.endPoint()}extracts/start?userId=${
            org.userId
          }&platform=${platform}&organisationId=${org.organisationId}${
            monthsOfData ? "&monthsOfData=" + monthsOfData : ""
          }`
        )
        .then(() => {
          this.showMessage(
            "success",
            "Analysis for " + this.organisations[org.organisationId].name + " successfully started"
          );
        })
        .catch(e => {
          if (e.response.status == 409) {
            this.showMessage(
              "info",
              "There's an extract in progress for this organisation already.",
              "Extract in progress"
            );
          } else {
            this.showMessage(
              "warning",
              "Sorry, the analysis for " +
                this.organisations[org.organisationId].name +
                " could not be started."
            );
          }
        })
        .finally(() => this.$set(org, "busy", false));
    },

    showMessage(variant, message, title) {
      EventBus.$emit("show-toast", { message: message, variant: variant, title: title });
    }
  }
};
</script>
