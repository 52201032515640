<template>
  <div id="account">
    <vue-headful :title="title" />

    <b-container>
      <h2 id="account-title">Account Details</h2>
      <b-tabs id="account-tabbing" content-class="mx-3 mt-4">
        <b-tab title="General" active key="1">
          <UserInformationCards :user="user" v-on:updateUser="updateUser" />
          <UserInformationForm :user="user" v-on:updateUser="updateUser" />
        </b-tab>
        <b-tab :disabled="!userId" title="Integrations" @click="$refs.orgLinks.lazyLoadOrgs()">
          <UserOrgsAndIntegrations ref="orgLinks" :userId="userId" :admin="isAdmin" />
        </b-tab>
        <b-tab title="Actions" :disabled="busy">
          <UserAccountActions :user="user" :admin="isAdmin" />
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#account {
  .container {
    max-width: 1500px;

    & > h2 {
      border-bottom: none;
      text-align: center;
    }

    #account-tabbing .nav.nav-tabs {
      border: none;

      .nav-item {
        padding: 0 10px 0 10px;

        .nav-link {
          border: none;
          border-bottom: 4px solid;
          border-color: $color-grey-lighter2;
          color: $color-font-headings;
        }

        .nav-link:hover {
          border-color: $color-font-para;
        }

        .nav-link.active {
          border-color: $color-pink-main;
        }

        @media screen and (max-width: 575px) {
          flex-grow: 1 !important;
          .nav-link {
            text-align: center;
          }
        }

        @media screen and (max-width: 767px) {
          padding: 0 5px 0 5px;
        }
      }

      @media screen and (max-width: 800px) {
        justify-content: center !important;
      }
    }

    #account-title {
      margin: 0 !important;
      padding-bottom: 0.5rem;
      text-align: left;

      @media screen and (max-width: 800px) {
        text-align: center !important;
      }
    }
  }
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import CognitoAuth from "@/cognito/cognitoauth";
import TokenHelper from "@/cognito/tokenhelper";
import UserInformationForm from "@/components/UserInformationForm";
import UserInformationCards from "@/components/UserInformationCards";
import UserOrgsAndIntegrations from "@/components/UserOrgsAndIntegrations";
import UserAccountActions from "@/components/UserAccountActions";

export default {
  name: "account",
  components: {
    UserInformationForm,
    UserInformationCards,
    UserOrgsAndIntegrations,
    UserAccountActions
  },
  async created() {
    this.fetchUser();
    this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
  },
  data() {
    return {
      title: "Reducer - Account Details",
      user: {},
      userId: null,
      busy: false,
      isAdmin: false
    };
  },
  methods: {
    async fetchUser() {
      var token = await CognitoAuth.getCurrentUserAccessToken();
      this.userId = TokenHelper.parseJwtToken(token).sub;
      this.busy = true;

      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}users/${this.userId}`);

        if (response.data.status === "success") {
          this.user = response.data.user;
        } else {
          this.showMessage("warning", "There was a problem getting user account details.");
        }
      } catch {
        this.showMessage("warning", "There was a problem getting user account details.");
      } finally {
        this.busy = false;
      }
    },
    updateUser(updatedUser) {
      Object.assign(this.user, updatedUser);
    },
    showMessage(variant, message) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>
